
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 30px;
  height: 90vh;
}

.login-container .login-box {
  position: relative;
  max-width: 850px;
  width: 100%;
  background-color: #fff;
  padding: 40px 30px;
  box-shadow: 0 5px 10px var(--shadow);
  perspective: 2700px;
}

.login-box .cover {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 50%;
  z-index: 98;
  transition: all 1s ease;
  transform-origin: left;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 30%;
}

.login-box .cover::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--color-1-d);
  border-top-right-radius: 30%;
}

.login-box .cover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--color-1-d);
  border-top-right-radius: 30%;
}

.login-box #flip:checked ~ .cover {
  transform: rotateY(-180deg);
}

.login-box .cover .front,
.login-box .cover .back {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box .cover img {
  filter: brightness(0) invert(1);
  position: absolute;
  height: 50%;
  width: 50%;
  object-fit: contain;
  z-index: 12;
  backface-visibility: hidden;
}

.login-box .cover .back .evit-back-logo {
  transform: rotateY(180deg);
}

.login-box .form {
  height: 100%;
  width: 100%;
  /* background-color: chartreuse; */
}

.login-box .form-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-content .login-form,
.form-content .signup-form {
  width: calc(50% - 25px);
}

.form-content .title {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  position: relative;
}

.form-content .title:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 25px;
  left: 0;
  bottom: 0;

  background: var(--color-1);
}

.signup-form .title:before {
  width: 20px;

  background: var(--color-1);
}

.form-content .input-boxes {
  margin-top: 30px;
}

.form-content .input-box {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  margin: 10 0px;
  position: relative;
}

.form-content .justify{
  display: flex;
  justify-content: center;
} 

.form-content .input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  padding: 0px 30px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}
.form-content .input-box input:focus,
.form-content .input-box input:valid {
  border-color: var(--color-1);
}

.form-content .input-icon {
  /* position: absolute; */
  color: var(--color-1);
  font-size: 17px;
  margin-top: 25px;
}

.form-content .text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.form-content label {
  color: var(--color-1-d);
}

.form-content label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.form-content .button {
  color: #fff;
  margin-top: 40px;
  margin-bottom: 10px;
}

.form-content .button input {
  color: #fff;
  background: var(--color-1-d);
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  transition: all 0.4s ease;
}

.form-content .button input:hover {
  /* opacity: 0.7; */
  background: var(--color-1);
}

.form-content .signup-text,
.form-content .login-text {
  text-align: center;
  margin-top: 25px;
}

.login-box #flip{
  display: none;
}

@media (max-width: 730px) {

  .login-box .cover {
    display: none;
  }

  .form-content .login-form,
  .form-content .signup-form {
    width: 100%;
  }

  .form-content .signup-form {
    display: none;
  }

  .login-box #flip:checked ~ form .signup-form{
    display: block;
  }

  .login-box #flip:checked ~ form .login-form{
    display: none;
  }
}

@media (max-width: 400px){
  .login-container .login-box{
    padding: 30px 10px;
  }
}
