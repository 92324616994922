.authBox1{
	width : 800px;
	overflow :hidden;
	margin : 80px auto 30px
}

body { 
	background: white
	}

.leftBox{
	width:300px;
	height : 480px;
	float: left;
	position: relative;
	display: block;
}
.bgGreen{
width :100%;
height :100%;
background-color:#039453;;
border-top-left-radius: 100px;
}

.imageAuth{
	background-image: url("http://evitpl.com/evilogo1.png");
	width: 100%;	
	height: 100%;
	background-position: center;
	background-size: cover;
	background-repeat:no-repeat;
	border-top-left-radius: 90px;
	opacity: 0.5; 
	position:absolute;
	top :0;
	left:0;
}

.imageAuth{
	font-size: 35px;
	position: absolute;
	color: #ffffff;
}

.imageText{
	font-style: 35px;
	position: absolute;
	color: #ffffff;
}

.imageText.bold{
	font-weight: bold;
}
.imageText.style11{
	top:200px;
	left :20px;
}
.imageText.style21{
	top:250px;
	left :20px;
}

.rightBox1{
	width: 600px;
	height :480px;
	float:left;
	border: 1px solid#0f0f0f;
}

.box{
	width:  310px;
	height : 415px;
	margin : 32px auto;
}

.titleAuth{
	font-size: 22px;
	text-align: center;
	font-weight: bold;
	margin-bottom: 30px;
	/* color:#0a0a0a;; */
}

.inputSBox1{
	width : 300px;
	height :42px;
	border-bottom: 2px solid #d8d8d8;
	margin-bottom : 200px;
}

.inputS1{
	width:  130%;
	height: 100%;
	font-size: 20px;
	color: #727172;
	text-indent: 18px;
	border: 0;
	outline: none;
	margin-bottom: 100%;
}

.dropdown-menu1{
	width : 300px;
	height :42px;
	border-bottom: 2px solid #d8d8d8;
	margin-bottom : 200px;
}
.contentBox1{
	overflow: hidden;
	height : 42px;
	margin-bottom: 30px;
}
.checkboxBox{
	overflow: hidden;
	float: left;
}
.checkbox{
	float: left;
}
.checkboxLabel{
	float: left;
	font-size : 14px;
	margin-left: 5px;
	color : #37353f;
}
.btnAuth{
	margin-bottom: auto;
	width: 180px;
	height: 47px;
	color: #ffffff;
	background-color: #080808;
	text-align: center;
	line-height: 47px;
	border-radius: 10px;
	cursor: pointer;
	font-size:20px;
}

.borderBox{
	width: 300px;
	height : 47px;
	position: relative;
	margin : 20px 0;
}


.text1{
	float : right;
}
.text2{
	font-size : 40px;
	color: #818487
}

.btnAuth{
	margin-bottom: auto;
}
.socialMediaBox{
	overflow: hidden;
	width: 184px;
	height : 47px;
	margin : 0 auto;

}
.dropdown-menu1{
	margin-bottom: auto;
}
.icAuth{
	width: 41px;
	height: 45px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	float: left;
	margin-left: 30px;
	cursor :pointer;
}

.icAuth:last-child{
	margin-right: 0;

}

@media screen and (max-width : 768px){
	.authBox{
		width: 100;
		margin-bottom: auto;
	}
	.leftBox{
		display: none;
	}
}
