.container {
  height: calc(100vh - 50px);
  width: "100%";
  flex-direction: column;
  background: var(--color-1-xl);

}

.mapContainer {
  height: calc(100vh - 50px);
  width: calc(100% - var(--station-side-nav-width));
  flex-direction: column;
}

.station-sidebar {
  background-color: var(--nav-bg);
  margin-right: 0;
  width: var(--station-side-nav-width);
  /* height: calc(100vh - 50px); */
  /* display: flex; */
  /* margin-left: -100%; */
  transition: 900ms;
  font-size: 1rem;
  font-weight: 500;
  /* flex: 0 0 100%; */
  overflow: auto;
  box-shadow: 1px 0px 0px 0px var(--shadow);
  /* z-index: 101; */
  /* float: left; */
  position: absolute;
  right: 0;
  /* top: 0; */
  /* bottom: 0; */
  /* background-color: rgb(255, 189, 127); */
}

.station-sidebar.active {
  margin-right: 0%;
  transition: 350ms;
}

.address-box {
  background-color: var(--color-1-d);
  font-size: 1.4rem;
  font-weight: 600;
  /* margin: 20px; */
  color: white;
  padding: 20px;
}

.address-box .location-icon {
  margin-right: 10px;
  font-size: 40px;
  /* color: ; */
}

.address-box .address {
  margin-top: 10px;
  font-size: 0.9rem;
  color: var(--color-5);
  text-decoration: none;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.chargers-view {
  margin: 20px;
}

.charger-card {
  max-width: 40%;
  border-radius: 5px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: var(--color-1);
  box-shadow: 2px 2px var(--shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.charger-card .cgrName {
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
}

.charger-card .cgrPrice {
  font-size: 0.8rem;
  font-weight: 700;
  color: white;
}

@media (max-width: 730px){
  .mapContainer {
    height: calc(100vh - var(--station-side-nav-width)/2 - 50px);
    width: 100% ;
    flex-direction: column;
  }


  .station-sidebar {
    background-color: var(--nav-bg);
    width: 100%;
    transition: 900ms;
    font-size: 1rem;
    font-weight: 500;
    overflow: auto;
    box-shadow: 1px 0px 0px 0px var(--shadow);
    position: absolute;
    top: calc(100vh - var(--station-side-nav-width)/2 );
  }
  
  .station-sidebar.active {
    /* margin-right: 0%; */
    transition: 350ms;
  }

}