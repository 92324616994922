@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'PT Sans', sans-serif; */
}
html,
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin: 0rem;
  padding: 0rem;
}

:root {
  --color-1-xl:rgb(244, 255, 251);
  --color-1-l: #2bc87fa1;
  --color-1: #2bc87f;
  --color-1-d: #26a96c;

  --color-2: #32325d;
  --color-2-l: #32325dad;

  --color-3: #ced4da;
  --color-4: #212529;

  --color-5: #f4f4f4;

  --shadow: rgb(15 34 58 / 12%);
  font-size: 16px;

  --side-nav-width: 250px;
  --station-side-nav-width: 250px;

  --nav-bg: white;
}

.App {
  margin-left: 250px;
  margin-left: var(--side-nav-width);
  margin-top: 50px;
}

button {
  cursor: pointer;
}
.btn {
  padding: 6px 18px;

  border: 1px solid;

  font-size: 1rem;
  font-family: inherit;
  margin: 0.5rem 2px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

.btn.icon svg {
  min-height: 2rem;
  min-width: 2rem;
}
.btn.icon-l svg {
  margin-right: 5px;
  margin-bottom: -2px;
}

.btn.icon-r svg {
  margin-left: 5px;
  margin-bottom: -2px;
}

.btn.white.text {
  background-color: transparent;
  border-color: transparent;
  color: white;
}
.btn.green {
  background-color: #2bc87f;
  background-color: var(--color-1);
  border-color: #2bc87f;
  border-color: var(--color-1);
  color: white;
}

.btn.green:hover {
  background-color: transparent;
  color: #2bc87f;
  color: var(--color-1);
}

.btn.red {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}
.btn.red:hover {
  background-color: transparent;
  color: #dc3545;
}

.btn.green-transparent {
  background-color: transparent;
  border-color: #2bc87f;
  border-color: var(--color-1);
  color: #2bc87f;
  color: var(--color-1);
}
.btn.green-transparent:hover {
  background-color: #2bc87f;
  background-color: var(--color-1);
  color: white;
}

.btn.red-transparent {
  background-color: transparent;
  border-color: #dc3545;
  color: #dc3545;
}
.btn.red-transparent:hover {
  background-color: #dc3545;
  color: white;
}

.btn.blue-transparent {
  background-color: transparent;
  border-color: #0062cc;
  color: #0062cc;
}
.btn.blue-transparent:hover {
  background-color: #0062cc;
  color: white;
}

.btn.info-transparent {
  background-color: transparent;
  border-color: #17a2b8;
  color: #17a2b8;
}
.btn.info-transparent:hover {
  background-color: #17a2b8;
  color: white;
}

.btn.rounded {
  border-radius: 0.25rem;
}

.btn.btn-text-1 {
  color: #2bc87f;
  color: var(--color-1);
  background: transparent;
  border: none;
}

/* Text positioning */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
/* Text positioning */

/* form */
.form-container {
  padding: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #32325dad;
  color: var(--color-2-l);
}

.form-control {
  width: 100%;
  padding: 0.25rem 0.35rem;
  border-radius: 0.25rem;
  border: 1px solid #32325dad;
  border: 1px solid var(--color-2-l);
  height: calc(1em + 1.5rem + 2px);
  font-size: 0.85rem;
  color: #32325d;
  color: var(--color-2);
}

.form-container {
  margin: auto;
  width: 100%;
  padding: 1rem;
}

.form-container h2 {
  margin-bottom: 1rem;
  color: #32325d;
  color: var(--color-2);
  text-align: left;
  text-transform: uppercase;
}

.field-coloum-2-1 {
  -webkit-column-count: 2;
          column-count: 2;
  grid-column-gap: 4rem;
  -webkit-column-gap: 4rem;
          column-gap: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.form .btn {
  float: right;
}

.login-heading {
  margin: auto;
  transform: rotateZ(317deg);
  color: white;
}
/* Height width  */

.w-50 {
  width: 50%;
}
.h-100 {
  height: 100%;
}

/* Display */
.d-flex {
  display: flex;
}

/* text-Positioning */
.text-center {
  text-align: center;
}

.m-auto {
  margin: auto;
}

.pd-20{
  padding: 20px;
}

.relative-center {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* React Modal */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #00000069 !important;
}

.Modal {
  width: auto;
  height: auto;
  min-width: 500px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1023;
}

.Modal .modal-header {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #32325d;
  color: var(--color-2);
  box-shadow: 1px -1px 7px grey;
  margin-bottom: 1rem;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
}

.modal-heading {
  width: auto;
}

.modal-close {
  margin-left: auto;
  background: transparent;
  border: navajowhite;
  color: gray;
}

.Modal .modal-body {
  padding: 1rem;
  width: 100%;
}

/* React Confirm Alert */
.react-confirm-alert-overlay {
  background-color: #0b0b0b8f !important;
}

/* CUstom Modal */
.custom-modal {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;

  width: 100%;
}

.confirm-modal .heading {
  padding: 1rem;
  border-bottom: 1px solid gray;
  font-size: 1.25rem;
  font-weight: 500;
}

.confirm-modal .body {
  padding: 1rem;
  border-bottom: 1px solid gray;
}

.confirm-modal .footer {
  padding: 1rem;
  text-align: right;
}
/* Custom alert mmodal */
.react-confirm-alert {
  width: auto;
  max-width: 80%;
}
.feedback-modal {
  padding: 1rem;
}

.feedback-modal .success {
  color: green;
}

.feedback-modal .error {
  color: red;
}

.feedback-modal .btn.btn-success {
  background: green;
  color: white;
}

.feedback-modal .btn.btn-error {
  background: darkred;
  color: white;
}

.feedback-modal .feedback-modal-image {
  width: 100px;
}

body {
  background: #ffffff;
  background: linear-gradient(to right, #ffffff, #ffffff);
  margin: 0rem;
  padding: 0rem;
}

.otp-container {
  width: 100%;
  overflow: hidden;
  /* min-width: 300px; */
}

.otp-input-container {
  margin: 10 0px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

/* .otp-input-container .otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.3rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
} */

.otp-input-container .inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.5rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.otp-container .OtpButton .button {
  width: 100%;
  outline: none;
  border: none;
  padding: 0px 30px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  color: #fff;
  background: var(--color-1-d);
  padding: 20px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.otp-container .text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  line-height: 1.5;
}

.otp-container .otp-text {
  text-align: center;
  margin-top: 25px;
}

@media (max-width: 320px) {
  /* .otp-input-container .otpInput {
    width: 2.5rem !important;
    height: 2.5rem;
    margin: 0 0.1rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  } */

  .otp-input-container .inputStyle {
    width: 2.5rem !important;
    height: 2.5rem;
    margin: 0 0.15rem;
    font-size: 1.2rem;
  }
}


.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 30px;
  height: 90vh;
}

.login-container .login-box {
  position: relative;
  max-width: 850px;
  width: 100%;
  background-color: #fff;
  padding: 40px 30px;
  box-shadow: 0 5px 10px var(--shadow);
  perspective: 2700px;
}

.login-box .cover {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 50%;
  z-index: 98;
  transition: all 1s ease;
  transform-origin: left;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 30%;
}

.login-box .cover::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--color-1-d);
  border-top-right-radius: 30%;
}

.login-box .cover::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--color-1-d);
  border-top-right-radius: 30%;
}

.login-box #flip:checked ~ .cover {
  transform: rotateY(-180deg);
}

.login-box .cover .front,
.login-box .cover .back {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box .cover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  position: absolute;
  height: 50%;
  width: 50%;
  object-fit: contain;
  z-index: 12;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.login-box .cover .back .evit-back-logo {
  transform: rotateY(180deg);
}

.login-box .form {
  height: 100%;
  width: 100%;
  /* background-color: chartreuse; */
}

.login-box .form-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-content .login-form,
.form-content .signup-form {
  width: calc(50% - 25px);
}

.form-content .title {
  font-size: 24px;
  font-weight: 500;
  color: #333;
  position: relative;
}

.form-content .title:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 25px;
  left: 0;
  bottom: 0;

  background: var(--color-1);
}

.signup-form .title:before {
  width: 20px;

  background: var(--color-1);
}

.form-content .input-boxes {
  margin-top: 30px;
}

.form-content .input-box {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  margin: 10 0px;
  position: relative;
}

.form-content .justify{
  display: flex;
  justify-content: center;
} 

.form-content .input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  padding: 0px 30px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}
.form-content .input-box input:focus,
.form-content .input-box input:valid {
  border-color: var(--color-1);
}

.form-content .input-icon {
  /* position: absolute; */
  color: var(--color-1);
  font-size: 17px;
  margin-top: 25px;
}

.form-content .text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.form-content label {
  color: var(--color-1-d);
}

.form-content label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.form-content .button {
  color: #fff;
  margin-top: 40px;
  margin-bottom: 10px;
}

.form-content .button input {
  color: #fff;
  background: var(--color-1-d);
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  transition: all 0.4s ease;
}

.form-content .button input:hover {
  /* opacity: 0.7; */
  background: var(--color-1);
}

.form-content .signup-text,
.form-content .login-text {
  text-align: center;
  margin-top: 25px;
}

.login-box #flip{
  display: none;
}

@media (max-width: 730px) {

  .login-box .cover {
    display: none;
  }

  .form-content .login-form,
  .form-content .signup-form {
    width: 100%;
  }

  .form-content .signup-form {
    display: none;
  }

  .login-box #flip:checked ~ form .signup-form{
    display: block;
  }

  .login-box #flip:checked ~ form .login-form{
    display: none;
  }
}

@media (max-width: 400px){
  .login-container .login-box{
    padding: 30px 10px;
  }
}

.container {
  height: calc(100vh - 50px);
  width: "100%";
  flex-direction: column;
  background: var(--color-1-xl);

}

.mapContainer {
  height: calc(100vh - 50px);
  width: calc(100% - var(--station-side-nav-width));
  flex-direction: column;
}

.station-sidebar {
  background-color: var(--nav-bg);
  margin-right: 0;
  width: var(--station-side-nav-width);
  /* height: calc(100vh - 50px); */
  /* display: flex; */
  /* margin-left: -100%; */
  transition: 900ms;
  font-size: 1rem;
  font-weight: 500;
  /* flex: 0 0 100%; */
  overflow: auto;
  box-shadow: 1px 0px 0px 0px var(--shadow);
  /* z-index: 101; */
  /* float: left; */
  position: absolute;
  right: 0;
  /* top: 0; */
  /* bottom: 0; */
  /* background-color: rgb(255, 189, 127); */
}

.station-sidebar.active {
  margin-right: 0%;
  transition: 350ms;
}

.address-box {
  background-color: var(--color-1-d);
  font-size: 1.4rem;
  font-weight: 600;
  /* margin: 20px; */
  color: white;
  padding: 20px;
}

.address-box .location-icon {
  margin-right: 10px;
  font-size: 40px;
  /* color: ; */
}

.address-box .address {
  margin-top: 10px;
  font-size: 0.9rem;
  color: var(--color-5);
  text-decoration: none;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.chargers-view {
  margin: 20px;
}

.charger-card {
  max-width: 40%;
  border-radius: 5px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: var(--color-1);
  box-shadow: 2px 2px var(--shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.charger-card .cgrName {
  font-size: 0.9rem;
  font-weight: 600;
  color: white;
}

.charger-card .cgrPrice {
  font-size: 0.8rem;
  font-weight: 700;
  color: white;
}

@media (max-width: 730px){
  .mapContainer {
    height: calc(100vh - var(--station-side-nav-width)/2 - 50px);
    width: 100% ;
    flex-direction: column;
  }


  .station-sidebar {
    background-color: var(--nav-bg);
    width: 100%;
    transition: 900ms;
    font-size: 1rem;
    font-weight: 500;
    overflow: auto;
    box-shadow: 1px 0px 0px 0px var(--shadow);
    position: absolute;
    top: calc(100vh - var(--station-side-nav-width)/2 );
  }
  
  .station-sidebar.active {
    /* margin-right: 0%; */
    transition: 350ms;
  }

}
.loader-box {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #2bc87f;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: pulsate 1s ease-out;
          animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #2bc87fa1;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.tooltip-card {
  padding: 5px;
  /* max-width: 200px; */
}

.tooltip-card .station-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.tooltip-card .station-name .station-icon {
  font-size: 25px;
  color: var(--color-4);
  margin-right: 5px;
}


@media (max-width: 415px) {
  .tooltip-card {
    max-width: 200px;
  }

  .tooltip-card .station-name {
    font-size: 14px;
    font-weight: 600;
  }

  .tooltip-card .station-name .station-icon {
    font-size: 20px;
    margin-right: 3px;
  }

  .tooltip-card .station-add {
    max-width: 200px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/* Custom properties */
:root {
  --tooltip-text-color: var(--color-4);
  --tooltip-background-color: white;
  --tooltip-margin: 100px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: var(--color-4);
  color: var(--tooltip-text-color);
  background: white;
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(6px * -1);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(100px * -1);
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: white;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(100% + 100px);
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(6px * -1);
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: white;
  border-right-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(100px * -1);
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: white;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + 100px);
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(6px * -2);
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: white;
  border-left-color: var(--tooltip-background-color);
}


.authBox1{
	width : 800px;
	overflow :hidden;
	margin : 80px auto 30px
}

body { 
	background: white
	}

.leftBox{
	width:300px;
	height : 480px;
	float: left;
	position: relative;
	display: block;
}
.bgGreen{
width :100%;
height :100%;
background-color:#039453;;
border-top-left-radius: 100px;
}

.imageAuth{
	background-image: url("http://evitpl.com/evilogo1.png");
	width: 100%;	
	height: 100%;
	background-position: center;
	background-size: cover;
	background-repeat:no-repeat;
	border-top-left-radius: 90px;
	opacity: 0.5; 
	position:absolute;
	top :0;
	left:0;
}

.imageAuth{
	font-size: 35px;
	position: absolute;
	color: #ffffff;
}

.imageText{
	font-style: 35px;
	position: absolute;
	color: #ffffff;
}

.imageText.bold{
	font-weight: bold;
}
.imageText.style11{
	top:200px;
	left :20px;
}
.imageText.style21{
	top:250px;
	left :20px;
}

.rightBox1{
	width: 600px;
	height :480px;
	float:left;
	border: 1px solid#0f0f0f;
}

.box{
	width:  310px;
	height : 415px;
	margin : 32px auto;
}

.titleAuth{
	font-size: 22px;
	text-align: center;
	font-weight: bold;
	margin-bottom: 30px;
	/* color:#0a0a0a;; */
}

.inputSBox1{
	width : 300px;
	height :42px;
	border-bottom: 2px solid #d8d8d8;
	margin-bottom : 200px;
}

.inputS1{
	width:  130%;
	height: 100%;
	font-size: 20px;
	color: #727172;
	text-indent: 18px;
	border: 0;
	outline: none;
	margin-bottom: 100%;
}

.dropdown-menu1{
	width : 300px;
	height :42px;
	border-bottom: 2px solid #d8d8d8;
	margin-bottom : 200px;
}
.contentBox1{
	overflow: hidden;
	height : 42px;
	margin-bottom: 30px;
}
.checkboxBox{
	overflow: hidden;
	float: left;
}
.checkbox{
	float: left;
}
.checkboxLabel{
	float: left;
	font-size : 14px;
	margin-left: 5px;
	color : #37353f;
}
.btnAuth{
	margin-bottom: auto;
	width: 180px;
	height: 47px;
	color: #ffffff;
	background-color: #080808;
	text-align: center;
	line-height: 47px;
	border-radius: 10px;
	cursor: pointer;
	font-size:20px;
}

.borderBox{
	width: 300px;
	height : 47px;
	position: relative;
	margin : 20px 0;
}


.text1{
	float : right;
}
.text2{
	font-size : 40px;
	color: #818487
}

.btnAuth{
	margin-bottom: auto;
}
.socialMediaBox{
	overflow: hidden;
	width: 184px;
	height : 47px;
	margin : 0 auto;

}
.dropdown-menu1{
	margin-bottom: auto;
}
.icAuth{
	width: 41px;
	height: 45px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	float: left;
	margin-left: 30px;
	cursor :pointer;
}

.icAuth:last-child{
	margin-right: 0;

}

@media screen and (max-width : 768px){
	.authBox{
		width: 100;
		margin-bottom: auto;
	}
	.leftBox{
		display: none;
	}
}

.data-table table {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9em;
    border-collapse: collapse;
    width: 100%;
  }
  
 .data-table table td, .data-table table th {
    border: 1px solid var(--color-3);
    padding: 10px;
  }
  
  /* .data-table table tr:nth-child(even){background-color: #edf2f7;} */
  
  .data-table table tr:hover {background-color: #f7f7f7;}
  
  .data-table table th {
    padding: 10px;
    text-align: left;
    background-color: white;
    color: var(--color-2);
    
  }

  .data-table table thead{
    border-bottom: 2px solid #E7EBF0;
  }

  .data-table .table-pagination{
    text-align: center;
    padding: 1rem;
  }

 .data-table select{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: calc(1.5rem + 1em);
  background: transparent;
  border: 1px solid var(--color-2);
  border-radius: 0.25rem;
  color: var(--color-2);
  font-weight: bold;
 }
 
 .data-table-action{
   border-top: 1px solid;
   border-left: 1px solid;
   border-right: 1px solid;
   border-color: var(--color-3);
   padding: 0.5rem 1rem;
 }
.dropdown-menu {
    background: red;
    width: 200px;
    position: absolute;
    top: 50px;
    list-style: none;
    text-align: start;
    z-index: 1;
  }
  
  .dropdown-menu li {
    background : #039453;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: #080808;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }

  .dropdown-link {
    display: block;
    height: 200%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }
.navbar {
  background: linear-gradient(90deg, var(--color-1) 0%, var(--color-1) 100%);
  height: 50px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  box-shadow: 1px 1px 5px 2px var(--shadow);
  z-index: 1;
}
.img-logo{
  height: 40px;
}

.navbar-logo {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
  justify-self: start;
  align-self: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100%;
  justify-content: end;
  margin-right: 2rem;
}


.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: rgb(250, 249, 249);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(250, 249, 249);
  border-radius: 4px;
}

.nav-links:hover {
  background-color: #00923f;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #2e2e2e;
    left: 0;
    opacity: 0.9;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #00923f;;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 1px;
    left: 0;
    margin-left: 20px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 20%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #00923f;;  
    text-decoration: none;  
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #00923f;;
    transition: 250ms;
  }   

  button {
    /* display: none; */
  }
}

.bg-img {
  background-image: url("http://evitpl.com/evilogo1.png");
}

.main-logo a, 
.main-nav a {
  display: auto;
  color: green;
  text-decoration: none;
  text-align: center;
  padding: 8px 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.main-logo img {width:80%;}
.main-nav a::before {
  font-family: 'icomoon';
  content: attr(data-icon);
  color:green;
  position: absolute;
  top: 10px;
  bottom: 30px;
  left: 0%;
  transition: .4s;
}
/* .navbar {
    background-color: var(--color-1-d);
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
 padding: 1rem;
    color: white;
    z-index: 100;
    
    margin-left: var(--side-nav-width);
  } */

.translucent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.7;
  z-index: 99;
  display: none;
  transition: 900000ms;
}
.translucent.active {
  display: block;
  transition: 350000ms;
}

.menu-bars {
  color: white;
  font-size: 1.5rem;
}

.menu-bars-close {
  color: blue;
}

.sidebar {
  background-color: var(--nav-bg);
  margin-left: 0;
  width: var(--side-nav-width);
  height: 100%;
  /* height: calc(100vh - 50px); */
  /* display: flex; */
  /* margin-left: -100%; */
  transition: 900ms;
  font-size: 1rem;
  font-weight: 500;
  /* flex: 0 0 100%; */
  overflow: auto;
  box-shadow: 1px 0px 0px 0px var(--shadow);
  /* z-index: 101; */
  /* float: left; */
  position: fixed;
  left: 0;
  /* top: 0; */
  /* bottom: 0; */
}

.margin-top {
  height: 10px;
}

.sidebar.active {
  margin-left: 0%;
  transition: 350ms;
}

.sidebar ul {
  list-style: none;
  padding-left: 0;
  width: 100%;
  margin-top: 0;
}

.sidebar ul li {
  overflow: clip;
}

.nav-link {
  display: flex;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--color-2-l);
  text-decoration: none;
  font-weight: 600;
}

.nav-link svg {
  margin-right: 10px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
}

.nav-link.active {
  background: var(--color-5);
  color: var(--color-1);
}

.nav-link:hover {
  background: var(--color-3);
  color: var(--color-2);
  font-weight: 600;
}

.nav-link-close {
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-2);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.nav-link-close svg {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;

  font-size: 2rem;
  color: var(--color-2);
}

.nav-menu-items {
  background: blue;
  width: 100%;
}

.nav-text {
  color: white;
}

.nav-menu-items {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
}

.nav-logo {
  display: flex;
  justify-content: center;

  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  background: transparent;
  /* border-bottom: 2px solid #474179; */
  height: 40px;
}

.sidebar::-webkit-scrollbar {
  width: 0px;
}

.sidebar:hover::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #474179;
}

.sidebar::-webkit-scrollbar-track {
  background: #595b94;
}
/*
    .sidebar:hover:-webkit-scrollbar{
      width: 5px;
    } */

@media (max-width: 730px) {
  :root {
    --side-nav-width: 50px;
  }

  .nav-link {
    display: flex;
    padding-left: 2px;
    padding-right: 2px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-title {
    font-size: 8px;
    margin-top: 5px;
  }
}

.form-input-container{
    margin: 20px;
}

