.otp-container {
  width: 100%;
  overflow: hidden;
  /* min-width: 300px; */
}

.otp-input-container {
  margin: 10 0px;
  position: relative;
  margin-top: 30px;
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

/* .otp-input-container .otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.3rem;
  font-size: 2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
} */

.otp-input-container .inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 0.5rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.otp-container .OtpButton .button {
  width: 100%;
  outline: none;
  border: none;
  padding: 0px 30px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  color: #fff;
  background: var(--color-1-d);
  padding: 20px;
  cursor: pointer;
  transition: all 0.4s ease;
}

.otp-container .text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  line-height: 1.5;
}

.otp-container .otp-text {
  text-align: center;
  margin-top: 25px;
}

@media (max-width: 320px) {
  /* .otp-input-container .otpInput {
    width: 2.5rem !important;
    height: 2.5rem;
    margin: 0 0.1rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  } */

  .otp-input-container .inputStyle {
    width: 2.5rem !important;
    height: 2.5rem;
    margin: 0 0.15rem;
    font-size: 1.2rem;
  }
}
