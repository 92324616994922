/* .navbar {
    background-color: var(--color-1-d);
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
 padding: 1rem;
    color: white;
    z-index: 100;
    
    margin-left: var(--side-nav-width);
  } */

.translucent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.7;
  z-index: 99;
  display: none;
  transition: 900000ms;
}
.translucent.active {
  display: block;
  transition: 350000ms;
}

.menu-bars {
  color: white;
  font-size: 1.5rem;
}

.menu-bars-close {
  color: blue;
}

.sidebar {
  background-color: var(--nav-bg);
  margin-left: 0;
  width: var(--side-nav-width);
  height: 100%;
  /* height: calc(100vh - 50px); */
  /* display: flex; */
  /* margin-left: -100%; */
  transition: 900ms;
  font-size: 1rem;
  font-weight: 500;
  /* flex: 0 0 100%; */
  overflow: auto;
  box-shadow: 1px 0px 0px 0px var(--shadow);
  /* z-index: 101; */
  /* float: left; */
  position: fixed;
  left: 0;
  /* top: 0; */
  /* bottom: 0; */
}

.margin-top {
  height: 10px;
}

.sidebar.active {
  margin-left: 0%;
  transition: 350ms;
}

.sidebar ul {
  list-style: none;
  padding-left: 0;
  width: 100%;
  margin-top: 0;
}

.sidebar ul li {
  overflow: clip;
}

.nav-link {
  display: flex;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--color-2-l);
  text-decoration: none;
  font-weight: 600;
}

.nav-link svg {
  margin-right: 10px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
}

.nav-link.active {
  background: var(--color-5);
  color: var(--color-1);
}

.nav-link:hover {
  background: var(--color-3);
  color: var(--color-2);
  font-weight: 600;
}

.nav-link-close {
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-2);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.nav-link-close svg {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;

  font-size: 2rem;
  color: var(--color-2);
}

.nav-menu-items {
  background: blue;
  width: 100%;
}

.nav-text {
  color: white;
}

.nav-menu-items {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
}

.nav-logo {
  display: flex;
  justify-content: center;

  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  background: transparent;
  /* border-bottom: 2px solid #474179; */
  height: 40px;
}

.sidebar::-webkit-scrollbar {
  width: 0px;
}

.sidebar:hover::-webkit-scrollbar {
  width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #474179;
}

.sidebar::-webkit-scrollbar-track {
  background: #595b94;
}
/*
    .sidebar:hover:-webkit-scrollbar{
      width: 5px;
    } */

@media (max-width: 730px) {
  :root {
    --side-nav-width: 50px;
  }

  .nav-link {
    display: flex;
    padding-left: 2px;
    padding-right: 2px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav-title {
    font-size: 8px;
    margin-top: 5px;
  }
}
