.data-table table {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9em;
    border-collapse: collapse;
    width: 100%;
  }
  
 .data-table table td, .data-table table th {
    border: 1px solid var(--color-3);
    padding: 10px;
  }
  
  /* .data-table table tr:nth-child(even){background-color: #edf2f7;} */
  
  .data-table table tr:hover {background-color: #f7f7f7;}
  
  .data-table table th {
    padding: 10px;
    text-align: left;
    background-color: white;
    color: var(--color-2);
    
  }

  .data-table table thead{
    border-bottom: 2px solid #E7EBF0;
  }

  .data-table .table-pagination{
    text-align: center;
    padding: 1rem;
  }

 .data-table select{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: calc(1.5rem + 1em);
  background: transparent;
  border: 1px solid var(--color-2);
  border-radius: 0.25rem;
  color: var(--color-2);
  font-weight: bold;
 }
 
 .data-table-action{
   border-top: 1px solid;
   border-left: 1px solid;
   border-right: 1px solid;
   border-color: var(--color-3);
   padding: 0.5rem 1rem;
 }