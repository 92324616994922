* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* font-family: 'PT Sans', sans-serif; */
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
html,
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin: 0rem;
  padding: 0rem;
}

:root {
  --color-1-xl:rgb(244, 255, 251);
  --color-1-l: #2bc87fa1;
  --color-1: #2bc87f;
  --color-1-d: #26a96c;

  --color-2: #32325d;
  --color-2-l: #32325dad;

  --color-3: #ced4da;
  --color-4: #212529;

  --color-5: #f4f4f4;

  --shadow: rgb(15 34 58 / 12%);
  font-size: 16px;

  --side-nav-width: 250px;
  --station-side-nav-width: 250px;

  --nav-bg: white;
}

.App {
  margin-left: var(--side-nav-width);
  margin-top: 50px;
}

button {
  cursor: pointer;
}
.btn {
  padding: 6px 18px;

  border: 1px solid;

  font-size: 1rem;
  font-family: inherit;
  margin: 0.5rem 2px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
}

.btn.icon svg {
  min-height: 2rem;
  min-width: 2rem;
}
.btn.icon-l svg {
  margin-right: 5px;
  margin-bottom: -2px;
}

.btn.icon-r svg {
  margin-left: 5px;
  margin-bottom: -2px;
}

.btn.white.text {
  background-color: transparent;
  border-color: transparent;
  color: white;
}
.btn.green {
  background-color: var(--color-1);
  border-color: var(--color-1);
  color: white;
}

.btn.green:hover {
  background-color: transparent;
  color: var(--color-1);
}

.btn.red {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}
.btn.red:hover {
  background-color: transparent;
  color: #dc3545;
}

.btn.green-transparent {
  background-color: transparent;
  border-color: var(--color-1);
  color: var(--color-1);
}
.btn.green-transparent:hover {
  background-color: var(--color-1);
  color: white;
}

.btn.red-transparent {
  background-color: transparent;
  border-color: #dc3545;
  color: #dc3545;
}
.btn.red-transparent:hover {
  background-color: #dc3545;
  color: white;
}

.btn.blue-transparent {
  background-color: transparent;
  border-color: #0062cc;
  color: #0062cc;
}
.btn.blue-transparent:hover {
  background-color: #0062cc;
  color: white;
}

.btn.info-transparent {
  background-color: transparent;
  border-color: #17a2b8;
  color: #17a2b8;
}
.btn.info-transparent:hover {
  background-color: #17a2b8;
  color: white;
}

.btn.rounded {
  border-radius: 0.25rem;
}

.btn.btn-text-1 {
  color: var(--color-1);
  background: transparent;
  border: none;
}

/* Text positioning */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
/* Text positioning */

/* form */
.form-container {
  padding: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--color-2-l);
}

.form-control {
  width: 100%;
  padding: 0.25rem 0.35rem;
  border-radius: 0.25rem;
  border: 1px solid var(--color-2-l);
  height: calc(1em + 1.5rem + 2px);
  font-size: 0.85rem;
  color: var(--color-2);
}

.form-container {
  margin: auto;
  width: 100%;
  padding: 1rem;
}

.form-container h2 {
  margin-bottom: 1rem;
  color: var(--color-2);
  text-align: left;
  text-transform: uppercase;
}

.field-coloum-2-1 {
  column-count: 2;
  column-gap: 4rem;
  margin-left: auto;
  margin-right: auto;
}

.form .btn {
  float: right;
}

.login-heading {
  margin: auto;
  transform: rotateZ(317deg);
  color: white;
}
/* Height width  */

.w-50 {
  width: 50%;
}
.h-100 {
  height: 100%;
}

/* Display */
.d-flex {
  display: flex;
}

/* text-Positioning */
.text-center {
  text-align: center;
}

.m-auto {
  margin: auto;
}

.pd-20{
  padding: 20px;
}

.relative-center {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* React Modal */
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #00000069 !important;
}

.Modal {
  width: auto;
  height: auto;
  min-width: 500px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1023;
}

.Modal .modal-header {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-2);
  box-shadow: 1px -1px 7px grey;
  margin-bottom: 1rem;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
}

.modal-heading {
  width: auto;
}

.modal-close {
  margin-left: auto;
  background: transparent;
  border: navajowhite;
  color: gray;
}

.Modal .modal-body {
  padding: 1rem;
  width: 100%;
}

/* React Confirm Alert */
.react-confirm-alert-overlay {
  background-color: #0b0b0b8f !important;
}

/* CUstom Modal */
.custom-modal {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;

  width: 100%;
}

.confirm-modal .heading {
  padding: 1rem;
  border-bottom: 1px solid gray;
  font-size: 1.25rem;
  font-weight: 500;
}

.confirm-modal .body {
  padding: 1rem;
  border-bottom: 1px solid gray;
}

.confirm-modal .footer {
  padding: 1rem;
  text-align: right;
}
/* Custom alert mmodal */
.react-confirm-alert {
  width: auto;
  max-width: 80%;
}
.feedback-modal {
  padding: 1rem;
}

.feedback-modal .success {
  color: green;
}

.feedback-modal .error {
  color: red;
}

.feedback-modal .btn.btn-success {
  background: green;
  color: white;
}

.feedback-modal .btn.btn-error {
  background: darkred;
  color: white;
}

.feedback-modal .feedback-modal-image {
  width: 100px;
}
