.navbar {
  background: linear-gradient(90deg, var(--color-1) 0%, var(--color-1) 100%);
  height: 50px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  box-shadow: 1px 1px 5px 2px var(--shadow);
  z-index: 1;
}
.img-logo{
  height: 40px;
}

.navbar-logo {
  filter: brightness(0) invert(1);
  justify-self: start;
  align-self: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100%;
  justify-content: end;
  margin-right: 2rem;
}


.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: rgb(250, 249, 249);
  text-decoration: none;
  padding: 0.5rem 1rem;
  border: 1px solid rgb(250, 249, 249);
  border-radius: 4px;
}

.nav-links:hover {
  background-color: #00923f;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 50px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #2e2e2e;
    left: 0;
    opacity: 0.9;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #00923f;;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 1px;
    left: 0;
    margin-left: 20px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 20%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #00923f;;  
    text-decoration: none;  
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #00923f;;
    transition: 250ms;
  }   

  button {
    /* display: none; */
  }
}

.bg-img {
  background-image: url("http://evitpl.com/evilogo1.png");
}

.main-logo a, 
.main-nav a {
  display: auto;
  color: green;
  text-decoration: none;
  text-align: center;
  padding: 8px 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.main-logo img {width:80%;}
.main-nav a::before {
  font-family: 'icomoon';
  content: attr(data-icon);
  color:green;
  position: absolute;
  top: 10px;
  bottom: 30px;
  left: 0%;
  transition: .4s;
}